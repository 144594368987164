import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../../services/data/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NoticeComponent } from '../notice/notice.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  formulario: FormGroup;

  private sentData: object;
  public crmActions: any = [
    {value : false, title : 'Curso', sendValue : 'course'}, 
    {value : false, title : 'Oferta', sendValue : 'offer'},
    {value : false, title : 'Contato', sendValue : 'contact'},
    {value : false, title : 'Evento', sendValue : 'event'},
    {value : false, title : 'Processo Seletivo', sendValue : 'selectiveProcess'},
    {value : false, title : 'Unidade', sendValue : 'unit'},
    {value : false, title : 'Contato Vinculado', sendValue : 'boundContact'}
  ];

  private sentencePtrn = "^([[R]{1}[B]{1}[A-Z]{2,}\.[WS]{2}\.[0-9\.]{3,}]{0,})$";
  private parametersPtrn = "^([A-Z]*\=[0-9, A-Z, \-\#\%\+\(\)]*\;){0,}$";

  public auth = false;
  public noAuth = false;
  public authNoClient = false;

  public update = false;

  constructor(
    private formBuilder: FormBuilder,
    private service: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private notice: NoticeComponent
  ) { }

  ngOnInit() {
    const state = localStorage.getItem('state');

    if (state === 'ok') {
      this.auth = true;
    } else if (state === 'authNoClient') {
      this.auth = true;
      this.authNoClient = true;
    } else if (state === 'Unauthorized') {
      this.noAuth = true;
    }

    if (this.route.snapshot.paramMap.get('id')) {
      this.getSentenceData(this.route.snapshot.paramMap.get('id'));
    }

    this.formulario = this.formBuilder.group({
      sentence: [null, [Validators.required/*,  Validators.pattern(this.sentencePtrn)*/]],                                                                                 
      colligate: [null, [Validators.required, Validators.minLength(1)]],
      system: [null, [Validators.required, Validators.minLength(1)]],
      action: this.formBuilder.group({
        course: [false],
        offer: [false],
        contact: [false],
        event: [false],
        selectiveProcess: [false],
        unit: [false]
      }, Validators.requiredTrue),
      event: [null],
      parameters: [null, Validators.pattern(this.parametersPtrn)],
      logname: [null]
    });
  }

  onSubmit() {
    this.sentData = this.formulario.value;
    this.sentData['client'] = localStorage.getItem('client');
    this.sentData['user'] = localStorage.getItem('user');
    this.sentData['jwt'] = localStorage.getItem('token');

    if (this.update) {
      this.service.resolve(
        'updateSentence/' + this.route.snapshot.paramMap.get('id'), 
        this.sentData
      ).then((data) => {
        if (data.success) {
          this.notice.showMessageSuccess(data);
        } else {
          this.notice.showMessageError(data);
        }
      });
    } else {
      this.service.resolve('saveSentence', this.sentData).then((data) => {
        if (data.success) {
          this.formulario.reset(this.formulario);
          this.notice.showMessageSuccess(data);
        } else {
          this.notice.showMessageError(data);
        }
      });
    }
  }

  getSentenceData(id: any) {
    let response: any;
    this.service.resolve(
      'sentence/' + id, 
      {
        jwt : localStorage.getItem('token'),
        client : localStorage.getItem('client'),
        user : localStorage.getItem('user')
      }).then((obj: any) => {

      response = obj.data[0];
      if (response && response.client_id == localStorage.getItem('client')) {
        this.formulario.controls.sentence.setValue(response.sentence);
        this.formulario.controls.event.setValue(response.event);
        this.formulario.controls.parameters.setValue(response.parameters);
        this.formulario.controls.system.setValue(response.system);
        this.formulario.controls.colligate.setValue(response.colligate);
        this.formulario.controls.logname.setValue(response.logname);
        this.formulario.controls.action['controls'].course.setValue(response.action.course);
        this.formulario.controls.action['controls'].offer.setValue(response.action.offer);
        this.formulario.controls.action['controls'].contact.setValue(response.action.contact);
        this.formulario.controls.action['controls'].event.setValue(response.action.event);
        this.formulario.controls.action['controls'].selectiveProcess.setValue(response.action.selectiveProcess);
        this.formulario.controls.action['controls'].unit.setValue(response.action.unit);
        this.update = true;
      } else {
        this.router.navigateByUrl('sentences');
      }
    });
  }

  goToSentences() {
    this.router.navigateByUrl('sentences');
  }
}
