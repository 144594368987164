import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Request } from './request';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly API = `${environment.api}`;
  // private readonly API = 'http://172.17.0.2/CRM/crm/api/';

  constructor(
    private httpClient: HttpClient
  ) { }

  get(request: Request) {
    const tokenStorage = localStorage.getItem('token');
    let token = '';
    if (tokenStorage) {
      token = '?token=' + tokenStorage;
    }

    const promise = this.httpClient.get(this.API + request.url + token).toPromise().then(
      res => request.successAction(res),
      msg => request.errorAction(msg));
    return promise;
  }

  getDonwload(request: Request) {
    const tokenStorage = localStorage.getItem('token');
    const clientStorage = localStorage.getItem('client');
    const userStorage = localStorage.getItem('user');
    let token = '';
    if (tokenStorage) {
      token = '?jwt=' + tokenStorage + '&client=' + clientStorage + '&user=' + userStorage;
    }

    const promise = this.httpClient.get(this.API + request.url + token, {responseType: 'text'}).toPromise();
    return promise;
  }



  post(request: Request) {
    // const tokenStorage = localStorage.getItem('token');
    // if (tokenStorage) {
    //   request.data.token = tokenStorage;
    // }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.httpClient.post(this.API + request.url, request.data, options)
    .toPromise()
    .catch((error) => {
      throw error.error || error.message || 'Erro desconhecido';
    });
  }

  createParams(data: {}) {
    let httpParams = new HttpParams();
    for ( const key in data) {
      if ( typeof data[key] === 'object') {
        for (let i = 0; i < data[key].length; i++) {
          httpParams = httpParams.append(key + '[]', data[key][i]);
        }
      } else {
        httpParams = httpParams.set(key, data[key]);
      }
    }
    return httpParams;
  }


  postFile(request: Request) {
    const tokenStorage = localStorage.getItem('token');
    if (tokenStorage) {
      request.data.token = tokenStorage;
    }

    const promise = this.httpClient.post(this.API + request.url, this.createParamsPostFile(request.data))
    .toPromise().then(
      res => request.successAction(res),
      msg => request.errorAction(msg));
      return promise;
  }

  createParamsPostFile(data: {}) {
    const httpParams = new FormData();

    for ( const key in data) {
      if (data.hasOwnProperty(key)) {
        httpParams.append(key, data[key]);
      }
    }
    return httpParams;
  }

  postToTracking(request: Request) {
    const tokenStorage = localStorage.getItem('token');
    if (tokenStorage) {
      request.data.token = tokenStorage;
    }

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const promise = this.httpClient.post(request.url, request.data, options).toPromise().then(
      res => request.successAction(res),
      msg => request.errorAction(msg));
      return promise;
  }

}
