import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Request } from '../http/request';
import { HttpService } from '../http/http.service';
import { Observable } from 'rxjs';
import { LoaderService } from '../../shared/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class DataService extends Request implements Resolve<DataService> {
  private response: any;
  constructor(
    private http: HttpService,
    private loaderService: LoaderService,
  ) {
    super();
  }

  resolve(
    uri: any,
    parameters: any
  ): Observable<any> | Promise<any> | any {
    this.loaderService.setChangesLoaderState(true);
    this.url = uri;

    this.data = parameters;
    return this.http.post(this)
      .then((data) => {
        this.loaderService.setChangesLoaderState(false);
        return data;
      })
      .catch((error) => {
        this.loaderService.setChangesLoaderState(false);
        return error;
      });
  }

  resolveDownload(
    uri: any,
    response: any
  ): Observable<any> | Promise<any> | any {
    this.url = uri;
    return this.http.getDonwload(this);
  }


  successAction(data) {
    this.response = JSON.parse(JSON.stringify(data));
    if (data.success) {
    } else {
      // if (data.error) {
      // }
    }
    this.loaderService.setChangesLoaderState(false);
  }

  errorAction(error) {
    if (error.error) {
    } else if (error.erro) {
    } else if (error.errors) {
    } else {
    }
    this.loaderService.setChangesLoaderState(false);
  }

}
