import { Component, Inject, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NoticeComponent } from 'src/app/components/notice/notice.component';
import { MatDialog } from '@angular/material';
import { DialogDeleteComponent } from '../dialog-delete/dialog-delete.component'

interface SentenceData {
	titleDialog: string,
	component: any,
	sql: string,
	sentece: {
		id: number,
		sentence: string
	},
	payload: {
		client: string,
		user: string,
		jwt: string,
	}
}

type ParamsSentence = {
	key: string,
	value: string
}

@Component({
	selector: 'app-dialog-sql-sentence',
	templateUrl: './dialog-sql-sentence.component.html',
	styleUrls: ['./dialog-sql-sentence.component.scss']
})
export class DialogSqlSentenceComponent implements OnInit {
	public oldSql: string = '';
	public fullScreen: boolean = false;
	public dados: SentenceData;
	public resultSentence: string = '';
	public paramsSentence: ParamsSentence[] = [{ key: '', value: '' }];
	public editorOptions = {
		theme: 'vs-dark',
		language: 'sql',
		readOnly: false,
		parameterHints: true,
		quickSuggestions: true,
		fontSize: 15,
		fontFamily: 'JetBrains Mono',
		automaticLayout: true
	};
	public editorResult = {
		theme: 'vs-dark',
		language: 'json',
		readOnly: false,
		parameterHints: true,
		quickSuggestions: true,
		fontSize: 15,
		fontFamily: 'JetBrains Mono',
		automaticLayout: true
	}

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<DialogSqlSentenceComponent>,
		private dialog: MatDialog,
		public service: DataService,
		public notice: NoticeComponent,
	) {
		this.dados = data;
		this.oldSql = this.dados.sql;
	}

	ngOnInit() {
		this.getParamsSentence();
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	onFullScree() {
		this.fullScreen = !this.fullScreen;
	}

	updateSqlSentence() {
		const dialogRef = this.dialog.open(DialogDeleteComponent, {
			width: '400px',
			panelClass: 'rbDefaultIsolatedModal',
			data: {
				titleDialog: 'Editar sentença',
				component: DialogDeleteComponent,
				data: {
					titulo: 'Esta ação não pode ser desfeita',
					subtitulo: 'Tem certeza que deseja editar a Sentença  ' + this.dados.sentece.sentence + '?',
					buttonLeft: { label: 'Cancelar', value: false },
					buttonRigth: { label: 'Sim, editar', value: true }
				},
				// payload: this.payload
			}
		})

		dialogRef.afterClosed().subscribe(
			editar => {
				if (editar == true) {
					this.editSqlSentence()
				}
			})
	}

	editSqlSentence() {
		this.service.resolve(`editSql/${this.dados.sentece.id}`, {
			new_sentence: this.dados.sql,
			old_sentence: this.oldSql,
			client: this.dados.payload.client,
			user: this.dados.payload.user,
			jwt: this.dados.payload.jwt
		})
			.then((result) => {
				if (result.success) {
					this.oldSql = this.dados.sql
					this.notice.showMessageSuccess('Sentença salva com sucesso...')
				} else {
					this.notice.showMessageError(result.data)
				}
			}).catch((err) => {
				this.notice.showMessageError(err);
			})
	}

	executeSentece() {
		const paramString = this.paramsSentence.map(param => { return `${param.key}=${param.value}` }).join(';') + ';';
		this.service.resolve(`executeSql/${this.dados.sentece.id}`, {
			parameters: paramString,
			client: this.dados.payload.client,
			user: this.dados.payload.user,
			jwt: this.dados.payload.jwt
		})
			.then((result) => {
				if (result.success) {
					this.resultSentence = JSON.stringify(result.data);
					this.notice.showMessageSuccess('Sentença executada com sucesso...')
				} else {
					this.notice.showMessageError(result.data)
				}
			}).catch((err) => {
				this.notice.showMessageError(err);
			})
	}

	getParamsSentence() {
		let sql = this.dados.sql

		sql = sql.replace(/\/\*[\s\S]*?\*\//g, '');

		let regex = /:\s*([a-zA-Z]+)/g;
		let result = []
		let match;
		while ((match = regex.exec(sql)) !== null) {
			if (!result.includes(match[1])) {
				result.push(match[1]);
			}
		}
		this.paramsSentence = result.map(element => {
			return { key: element, value: '' }
		})
	}

	indentMonaco(editor: any) {
		setTimeout(() => {
			editor.getAction('editor.action.formatDocument').run();
		}, 500)
	}
}
