
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data/data.service';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DialogSentenceComponent } from './../dialog/template/dialog-sentence/dialog-sentence.component';
import { DialogComponent } from './../dialog/dialog.component';
import { NoticeComponent } from '../notice/notice.component';
import { HistoricComponent } from "./../historic/historic.component";
import { DialogDeleteSentenceComponent } from '../dialog/template/dialog-delete-sentence/dialog-delete-sentence.component';
import { DialogChangeColumnsOrderComponent } from "../dialog/template/dialog-change-columns-order/dialog-change-columns-order.component";
import { CrudService } from 'src/app/shared/crud/crud.service';
import { DialogSqlSentenceComponent } from '../dialog/template/dialog-sql-sentence/dialog-sql-sentence.component';

export interface Sentences {
  id: number;
  sentence: string;
  colligate: string;
  system: string;
  event: string;
}

@Component({
  selector: 'app-sentences',
  templateUrl: './sentences.component.html',
  styleUrls: ['./sentences.component.scss']
})

export class SentencesComponent implements OnInit {

  public msgCrudEmpty: string = 'Não há registros!';
  private payload: object;
  public dataSource: any[] = new Array();
  public dataSourceCopy: any[] = new Array();
  public metaCount = this.dataSource.length;
  public menuOptions: any[] = [
    {
      icon: 'play_arrow', title: 'Executar', action: this.executeSentence, disabledParam: 'disabled',
    },
    // {
    //   icon: 'play_arrow', title: 'Iniciar execução auto', action: this.pauseSentence, disabledParam: 'disabled', visible : 'paused', visibleValue: 0
    // },
    // {
    //   icon: 'pause', title: 'Pausar execução auto', action: this.pauseSentence, disabledParam: 'disabled', visible : 'paused', visibleValue: 1
    // },
    {
        icon: 'edit', title: 'Editar', action: this.getSentenceData, disabledParam: 'disabled',
    },
    {
      icon: 'code', title: 'Visualizar SQL', action: this.viewSqlSentence, disabledParam: 'disabled',
    },
    {
      icon: 'gavel', title: 'Forçar Execução', action: this.forceSentence, disabledParam: 'disabled',
    },
    {
      icon: 'front_hand',  title: 'Parar Execução', action: this.stopSentence, disabledParam: 'disabled'
    },
    {
      icon: 'delete', title: 'Excluir', action: this.getDataDialogDelete, disabledParam: 'disabled',
    },
  ];

  public noSentences: boolean = false;
  public hasSentences: boolean = false;
  public loading = 0;
  public channels: any;
  public events: any;
  public intervals: any[] = new Array();
  public tbc: any;
  public categories: any;
  public update: string;



  columns: any[] = [
    { columnDef: 'id', header: 'ID', dataName: row => `${row.id}`, visibility: true, },
    { columnDef: 'sentence', header: 'Sentença', dataName: row => `${row.sentence}`, visibility: true },
    { columnDef: 'event', header: 'Evento', dataName: row => `${row.eventTitle != undefined ? row.eventTitle : '-'}`, visibility: true },
    { columnDef: 'colligate', header: 'Coligada', dataName: row => `${row.colligate}`, visibility: true },
    { columnDef: 'system', header: 'Sistema', dataName: row => `${row.system}`, visibility: true },
    { columnDef: 'tbc', header: 'TBC', dataName: row => `${row.tbc != undefined ? row.tbc : '-'}`, visibility: true },
    { columnDef: 'channel', header: 'Canal', dataName: row => `${row.channelName}`, visibility: true },
    { columnDef: 'cron', header: 'Horários de execução', dataName: row => `${ row.paused ? 'Execução automática pausada!' : (row.cron != undefined ? this.getCronTime(row.cron) : 'Sem execução programada')}`, visibility: true },
    { columnDef: 'lastExecution', header: 'Última execução', dataName: row => `${row.executing === '0' || row.executing == null ? row.execution : "Executando"}`, visibility: true },
    { columnDef: 'lastUpdate', header: 'Última alteração', dataName: row => `${row.update}`, action: this.OpenHistoric, visibility: true },
    { columnDef: 'status', header: 'Status', dataName: row => `${row.status_id ? row.status_id : "Não executado"}`, visibility: true },
    {
      columnDef: 'IconInfo', dataName: row => `${row.description ? row.description : ""}`, isIcon: true, icon: 'info', header: 'Descrição', visibility: true
    },
    {
      columnDef: 'buttonOptions',
      header: "Botões",
      dataName: row => `${row.id}`,
      isButton: true,
      isMenuButton: true,
      isFixedColumnEnd: true,
      buttonConfig: {
        icon: 'more_vert',
        title: null,
        isFixed: false,
      },
    },
  ];


  columnsRestore: any[] = [
    { columnDef: 'id', header: 'ID', dataName: row => `${row.id}`, visibility: true, },
    { columnDef: 'sentence', header: 'Sentença', dataName: row => `${row.sentence}`, visibility: true },
    { columnDef: 'event', header: 'Evento', dataName: row => `${row.eventTitle != undefined ? row.eventTitle : '-'}`, visibility: true },
    { columnDef: 'colligate', header: 'Coligada', dataName: row => `${row.colligate}`, visibility: true },
    { columnDef: 'system', header: 'Sistema', dataName: row => `${row.system}`, visibility: true },
    { columnDef: 'tbc', header: 'TBC', dataName: row => `${row.tbc != undefined ? row.tbc : '-'}`, visibility: true },
    { columnDef: 'channel', header: 'Canal', dataName: row => `${row.channelName}`, visibility: true },
    { columnDef: 'cron', header: 'Horários de execução', dataName: row => `${ row.paused ? 'Execução automática pausada!' : (row.cron != undefined ? this.getCronTime(row.cron) : 'Sem execução programada')}`, visibility: true },
    { columnDef: 'lastExecution', header: 'Última execução', dataName: row => `${row.executing === '0' || row.executing == null ? row.execution : "Executando"}`, visibility: true },
    { columnDef: 'lastUpdate', header: 'Última alteração', dataName: row => `${row.update}`, action: this.OpenHistoric, visibility: true },
    { columnDef: 'status', header: 'Status', dataName: row => `${row.status_id  ? row.status_id : "Não executado"}`, visibility: true },
    {
      columnDef: 'IconInfo', dataName: row => `${row.description ? row.description : ""}`, isIcon: true, icon: 'info', header: 'Descrição', visibility: true
    },
    {
        columnDef: 'buttonOptions',
        header: "Botões",
        dataName: row => `${row.id}`,
        isButton: true,
        isMenuButton: true,
        isFixedColumnEnd: true,
        buttonConfig: {
          icon: 'more_vert',
          title: null,
          isFixed: false,
        },
      },
  ];

  filter: any;
  pageIndex = 1;
  pageSize = 25;
  filterInit: any;

  columnFiltered: any[] = [
    {
      placeholder: "Evento",
      property: 'event',
      options: [],
      default: []
    },
    {
      placeholder: "Categorias",
      property: 'category',
      options: [],
      default: []
    },
    {
      placeholder: "Período de última execução",
      property: 'last_execution',
      date: true,
      options: null
      // default: {
      //   begin: null,
      //   end: null
      // }
    },
    {
      placeholder: "Período de última alteração",
      property: 'updated_at',
      date: true,
      options: null
      // default: {
      //   begin:null,
      //   end: null
      // }
    },
  ];

  @ViewChild('changeButtonHover', { static: false }) private changeButton: ElementRef;


  constructor(
    private router: Router,
    private dialog: MatDialog,
    private service: DataService,
    private notice: NoticeComponent,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private crudService: CrudService,
    private historic: MatDialog,
    private historicLog: DataService
  ) {
    this.matIconRegistry.addSvgIcon(
      "logo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/logo.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/edit.svg")
    );

    this.matIconRegistry.addSvgIcon(
      "trash",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../../assets/icons/trash.svg")
    );

    crudService.changeStatusFilter(true);
  }

  ngOnInit() {
    //pegar token e demais dados para carregar o layout
    const state = localStorage.getItem('state');

    if (state != 'ok') {
      this.router.navigate(['']);
    }

    this.payload = {
      client: localStorage.getItem('client'),
      user: localStorage.getItem('user'),
      jwt: localStorage.getItem('token')
    };

    this.hasFilter();

    this.service.resolve('persona-column-exists', this.payload).then((data) => {

      if (data.success == false) {
        this.columns = [
          { columnDef: 'id', header: 'ID', dataName: row => `${row.id}`, visibility: true, },
          { columnDef: 'sentence', header: 'Sentença', dataName: row => `${row.sentence}`, visibility: true },
          { columnDef: 'event', header: 'Evento', dataName: row => `${row.eventTitle != undefined ? row.eventTitle : '-'}`, visibility: true },
          { columnDef: 'colligate', header: 'Coligada', dataName: row => `${row.colligate}`, visibility: true },
          { columnDef: 'system', header: 'Sistema', dataName: row => `${row.system}`, visibility: true },
          { columnDef: 'tbc', header: 'TBC', dataName: row => `${row.tbc != undefined ? row.tbc : '-'}`, visibility: true },
          { columnDef: 'channel', header: 'Canal', dataName: row => `${row.channelName}`, visibility: true },
          { columnDef: 'cron', header: 'Horários de execução', dataName: row => `${ row.paused ? 'Execução automática pausada!' : (row.cron != undefined ? this.getCronTime(row.cron) : 'Sem execução programada')}`, visibility: true },
          { columnDef: 'lastExecution', header: 'Última execução', dataName: row => `${row.executing === '0' || row.executing == null ? row.execution : "Executando"}`, visibility: true },
          { columnDef: 'lastUpdate', header: 'Última alteração', dataName: row => `${row.update}`, action: this.OpenHistoric, visibility: true },
          { columnDef: 'status', header: 'Status', dataName: row => `${row.status_id  ? row.status_id : "Não executado"}`, visibility: true },
          {
            columnDef: 'IconInfo', dataName: row => `${row.description ? row.description : ""}`, isIcon: true, icon: 'info', header: 'Descrição', visibility: true
          },
          {
            columnDef: 'buttonOptions',
            header: "Botões",
            dataName: row => `${row.id}`,
            isButton: true,
            isMenuButton: true,
            isFixedColumnEnd: true,
            buttonConfig: {
              icon: 'more_vert',
              title: null,
              isFixed: false,
            },
          },
        ]
      } else {
        this.service.resolve('get-columns', this.payload).then((data) => {

          this.columns = this.orderColumns(JSON.parse(data.columns));
        });
      }
    });

    this.getSentences();
  }

  orderColumns(columns) {
    var order = [];

    columns.forEach((element, index) => {
      if (element.visibility) {
        order.push(this.columns.find(elm => elm.header == element.header))
      }
      this.columnsRestore[index].visibility = element.visibility;
    });
    return order;
  }

  getCronTime(cronExpression) {
    var cronString = '';
    var cronArray = [];
    const cron = cronExpression.split(" ");
    const cronMin = (cron[0].length == 2 ? cron[0] : '0' + cron[0])

    const cronHour = cron[1].split(",");
    cronHour.forEach(element => {
      if (element.length === 1 && typeof element != 'undefined') {
        cronArray[element] = '0' + element + ':' + cronMin;
      } else {
        cronArray[element] = element + ':' + cronMin;
      }
    });
    cronArray.sort();
    const filtered = cronArray.filter(function (ar) {
      return ar != null;
    });
    cronString = filtered.join(', ');
    return cronString;
  }

  getSentences() {
    this.loading = 0;
    this.service.resolve('sentences', this.payload).then((obj) => {
      if (obj.data.length != 0) {
        this.dataSource = obj.data;
        this.hasSentences = true;
        this.loading += 1;
      } else {
        this.dataSource = obj.data;
        this.hasSentences = false;
      }

      this.getChannel();
      this.getInterval();
      this.getEvent();
      this.getTbc();
      this.getCategorias();
    });
  }

  getChannel() {
    this.service.resolve('channels', this.payload).then((obj) => {
      this.channels = obj;
      this.dataSource = this.buildTitles(this.dataSource, this.channels.data, 'origin', 'nome', 'channelName');
      this.loading += 1;
      this.dataSourceCopy = JSON.parse(JSON.stringify(this.dataSource));
    });
  }

  getEvent() {
    let idEvents = [];
    this.service.resolve('events', this.payload).then((obj) => {
      this.events = obj;

      this.dataSource.map((elm) => {
        idEvents.push(elm.event)
      })

      this.events.data.map((evt) => {
        if(idEvents.includes(evt.id)) {
          this.columnFiltered[0].options.push({ id: evt.id, label: evt.titulo });
        }
      });
      this.dataSource = this.buildTitles(this.dataSource, this.events.data, 'event', 'titulo', 'eventTitle');
      this.loading += 1;
      this.dataSourceCopy = JSON.parse(JSON.stringify(this.dataSource));

    });
  }

  getInterval() {
    this.service.resolve('intervals', this.payload).then((obj) => {
      this.intervals = obj;
      let interval = obj.data;
      this.dataSource = this.buildTitles(this.dataSource, interval, 'interval', 'description', 'intervalTitle');
      this.loading += 1;
      this.dataSourceCopy = JSON.parse(JSON.stringify(this.dataSource));

    });
  }

  getTbc() {

    this.service.resolve('getTotvsData', this.payload).then((obj) => {
      this.tbc = obj;
      this.dataSource = this.buildTitles(this.dataSource, this.tbc, 'tbc_settings_id', 'discription', 'tbc');

      this.dataSourceCopy = JSON.parse(JSON.stringify(this.dataSource));

      this.loading += 1;
    });
  }

  getCategorias() {
    this.service.resolve('get-Categories', this.payload).then((obj) => {
      this.categories = obj;

      this.categories.data.map((evt) => {
        this.columnFiltered[1].options.push({ id: evt.id, label: evt.titulo });
      });
    });

  }

  hasFilter() {
    this.filter = JSON.parse(localStorage.getItem(location.pathname.replace("/", ""))) ?
    JSON.parse(localStorage.getItem(location.pathname.replace("/", ""))) : {};

    this.dataSourceCopy = JSON.parse(JSON.stringify(this.dataSource));

    this.testeFilter(this.filter);
  }


  buildTitles(array: any[], options, id, titleReference, title) {
    for (const item of array) {
      for (const opt of options) {
        if (opt.id == item[id]) {
          item[title] = opt[titleReference];
        }
      }
    }
    return array;
  }

  openCreateDialog() {
    this.router.navigate(['edit/']);
  }



  openEditDialog(data) {
    const dialog = this.dialog.open(DialogComponent, {
      width: '870px',
      minHeight: '595px',
      data: {
        titleDialog: 'Editar sentença',
        component: DialogSentenceComponent,
        data: data,
        payload: this.payload,
        channels: this.channels,
        events: this.events,
        intervals: this.intervals,
        tbc_options: this.tbc
      }
    });
    dialog.afterClosed().subscribe(
      close => {
        this.getSentences()
      }
    );
  }

  getSentenceData(id: any, parameter) {
    parameter.router.navigate(['edit/' + String(id.id)]);
  }

  getDataDialogDelete(id: any, parameter) {
    let response: any;
    parameter.service.resolve(
      'sentenceDataDelete/' + id.id, this.payload).then((obj: any) => {
        if (obj && obj[0]) {
          response = obj[0];
          parameter.openDialogDelete(response);
        }
      });
  };

  openDialogDelete(response) {
    this.dialog.open(DialogDeleteSentenceComponent, {
      minWidth: '400px',
      panelClass: 'rbDefaultIsolatedModal',
      data: { // Todos os dados (TBC e SendData)
        titleDialog: 'Excluir sentença',
        component: DialogDeleteSentenceComponent,
        data: response, //Dados apenas do TBC
        payload: this.payload
      }
    });
  };

  spliceCrud(id) {
    for (const [index, item] of this.dataSource.entries()) {
      if (item.id == id) {
        this.dataSource.splice(index, 1);
        this.crudService.deleteRowData(id);
      }
    }
  }


  executeSentence(id: any, parameter) {
    parameter.payload['force'] = false
    parameter.service.resolve('executeSentence/' + id.id, parameter.payload).then((data) => {
      if (data.success) {
        parameter.notice.showMessageSuccess(data);
      } else {
        parameter.notice.showMessageError(data);
      }
    });
    setTimeout(() => {
      parameter.getSentences();
    }, 2000);
  }

  forceSentence(id: any, parameter) {
    parameter.payload['force'] = true
    parameter.service.resolve('executeSentence/' + id.id, parameter.payload).then((data) => {
      if (data.success) {
        parameter.notice.showMessageSuccess(data);
      } else {
        parameter.notice.showMessageError(data);
      }
    })
  }

  pauseSentence(sentece: any, parameter) {
    parameter.payload['pause'] = !sentece.paused
    console.log(sentece, parameter);
    parameter.service.resolve('pausedSentence/' + sentece.id, parameter.payload).then((data) => {
        if (data.success) {
            sentece.paused = !sentece.paused;
        parameter.notice.showMessageSuccess(data);
      } else {
        parameter.notice.showMessageError(data);
      }
    })
  }
  viewSqlSentence(sentece: any, parameter) {
    parameter.service.resolve('sqlSentence/' + sentece.id, parameter.payload).then((data) => {
      if (data.success) {
        parameter.openDialogSqlSentence(data.sql, sentece);
        // parameter.notice.showMessageSuccess(data);
      } else {
        parameter.notice.showMessageError('Falha ao buscar a senteça!');
      }
    })
  }

  openDialogSqlSentence(logdata, sentece) {
    this.dialog.open(DialogSqlSentenceComponent, {
      width: '950px',
      panelClass: 'rbDefaultIsolatedModal',
      data: {
        titleDialog: 'Consulta parametrizada: '+sentece.sentence,
        component: DialogSqlSentenceComponent,
        sentece: sentece,
        sql: logdata,
        payload: this.payload,
      }
    });
  }
  OpenHistoric(id: any, parameter) {
    parameter.payload['sentence_id'] = id.id
    parameter.historicLog.resolve('getEditLogData', parameter.payload).then((obj) => {
      parameter.dialog.open(DialogComponent, {
        width: '750px',
        minHeight: '450px',
        data: {
          titleDialog: 'Histórico de alterações',
          component: HistoricComponent,
          data: id,
          historicLog: obj
        }
      });
    });
  }

  OpenAlterColumns() {
    var dialog = this.dialog.open(DialogChangeColumnsOrderComponent, {
      width: '800px',
      maxWidth: '400px',
      panelClass: 'rbDefaultIsolatedModal',
      autoFocus: false,
      data: {
        titleDialog: 'Colunas',
        component: DialogChangeColumnsOrderComponent,
        columns: this.columnsRestore
      }
    }); dialog.afterClosed().subscribe(
      result => {
        this.columns = this.columnsRestore.filter(elm => elm.visibility == true);
        this.changeButton['_elementRef'].nativeElement
        .classList.remove('cdk-program-focused');

        var findButton = this.columnsRestore.find(o => o.header === "Botões");
        if (findButton === undefined) {
          var backup =
            {
                columnDef: 'buttonOptions',
                header: "Botões",
                dataName: row => `${row.id}`,
                isButton: true,
                isMenuButton: true,
                isFixedColumnEnd: true,
                buttonConfig: {
                  icon: 'more_vert',
                  title: null,
                  isFixed: false,
                },
              };
          this.columns.push(backup);
        }

      }
    );
  }

  testeFilter(e) {

    this.columnFiltered.map((elm) => {
      if(e[elm.property]) {

        if(e[elm.property].date) {
          elm.default = {
            begin: null,
            end: null
          }
          elm.default.begin =  new Date(e[elm.property].value.begin);
          elm.default.end =  new Date(e[elm.property].value.end);
        } else {
          elm.default = e[elm.property].value;
        }
      }
    });

    localStorage.setItem(location.pathname.replace("/", ""), JSON.stringify(e));
    this.dataSource = JSON.parse(JSON.stringify(this.dataSourceCopy));
    if (e['search']) {
      var filterValue = e['search']['value'];

      filterValue = filterValue.trim(); // Remove whitespace

      this.dataSource = this.dataSource.filter((row) => {
        const dataStr = Object.keys(row).reduce((currentTerm: string, key: string) => {
          return (currentTerm + (row as { [key: string]: any })[key] + '◬');
        }, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        // console.log(dataStr);
        const transformedFilter = filterValue.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
        // console.log(dataStr.indexOf(transformedFilter) != -1);
        return dataStr.indexOf(transformedFilter) != -1;
      })

    };
    for (const key in e) {
      this.dataSource = this.dataSource.filter((row) => {
        var encontrado = false;
        if (e[key]['date']) {
          var dateStart = new Date(e[key]['value']['begin']);
          var dateEnd = new Date(e[key]['value']['end']);
          var dateRow = new Date(row[key]);

          if (!(dateRow.getTime() > 0)) {
            return false;
          }
          // console.log(dateStart);
          // console.log(dateEnd);
          // console.log(dateRow);
          // console.log(dateRow.getTime() + ' >= ' + dateStart.getTime() + ' && ' + dateRow.getTime() + ' <= ' + dateEnd.getTime());
          if (dateRow.getTime() >= dateStart.getTime() && dateRow.getTime() <= dateEnd.getTime()) {
            encontrado = true;
          }

        } else {

          if (Object.prototype.hasOwnProperty.call(e, key)) {
            for (let i = 0; i < e[key]['value'].length; i++) {
              const value = e[key]['value'][i];
              if (value['label'] == row[key] || value['id'] == row[key]) {
                encontrado = true;
              }
            }
          }
        }
        return encontrado
      });
    }
  }

  stopSentence(sentence: Sentences , self: SentencesComponent) {
    self.service.resolve('stopSentence/' + sentence.id, self.payload).then((data) => {
      if (data.success) {
        self.notice.showMessageSuccess(`A sentença ${sentence.id} foi retirada da fila de execução`);
      } else {
        self.notice.showMessageError(data.message);
      }
    }).catch((error) => {
      self.notice.showMessageError(error.message || 'Erro ao processar a requisição');
    });
  }
}
